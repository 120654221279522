.button1 {
    background-color: white;
    border: none;
    color: #333;
    padding: 15px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.addModule {
    background-color: #B5A084;
    border-color: #B5A084;
}

.infoButton {
    border-color: #B5A084;
    color: #B5A084;
}

.closeButton {
    background-color: #B5A084;
    border-color: #B5A084;
    color: #ffffff;
}

.actions button:hover {
    background-color: #111;
    color: white
}

.buttonIcon {
    background-color: white;
    border: none;
    color: #333;
    padding: 2px 14px;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.header-container {
    display:flex;
}
.header-text{
    padding-top: 20px;
}
.app-logo {
    height: 64px;
    pointer-events: none;
}

