ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  flex-grow: 1;
}

li {
  float: right;
}

li a {
  display: block;
  color: #333;
  text-align: center;
  padding: 14px 16px;
  padding-top:20px;
  text-decoration: none;
}

li a:hover {
  background-color: #111;
  color: white
}
li button:hover {
  background-color: #111;
  color: white
}
.width-384{
  width:384px;
}
.width-396{
  width:396px;
}
.width-400{
  width:400px;
}
.max-width-400{
  max-width: 400px;
}
.headerText{
  padding-right: 5px;
}
.module-rotate-90 {
  transform: rotate(0deg);
}

.module-rotate-180 {
  transform: rotate(270deg);
}

.module-rotate-270 {
  transform: rotate(180deg);
}

.module-rotate-0 {
  transform: rotate(90deg);
}
  .header-text{
    padding-right:5px;
  }

.vh_minus_header {
  height: calc(100vh - 12rem);
}
