.qrcodeBorder {
    border: black 1px solid;
  }

.qrcodeContent {          /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;
    margin-top:40px;
    margin-bottom:40px;
  }
  .subText{
    margin-top: 11px;
    color: #606060;
    font-size: 0.9em;
  }